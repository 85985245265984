<template>
  <div class="view-wrap-index">
    <!-- <nav-header></nav-header> -->
    <Header />
    <div class="view-router-box">
      <router-view></router-view>
    </div>
    <Footer />
    <!-- <public-footer></public-footer> -->
    <!-- <div id="footer"></div> -->
  </div>
</template>

<script>
import Header from "./comp/header.vue";
import Footer from "./comp/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  name: "Index",
  data() {
    return {};
  },
  created() {
    this.Init();
  },
  methods: {
    Init() { },
  },
};
</script>

<style lang="scss">
</style>
