<template>
  <div class="comp-footer">
    <div class="home-footer" :class="{ 'mb-home-footer': isMinDevice }">
      <div class="h-footer-item1" v-if="!isMinDevice">
        <div class="common-title">CONTACT INFO</div>
        <div class="">
          <div class="common-title">ADDRESS：</div>
          <div class="common-text">
            591 Mudan Street, Yuen Long, Yuen Long, Hong Kong
          </div>
        </div>
        <div class="">
          <div class="common-title">PHONE：</div>
          <div class="common-text">+00852-6362833</div>
        </div>
        <div class="">
          <div class="common-title">EMAIL：</div>
          <div class="common-text">
            <a
              href="mailto:ACEsoftKeys@yeah.net"
              target="_blank"
              rel="noopener noreferrer"
              style="color: #fff"
              >ACEsoftKeys@yeah.net</a
            >
          </div>
        </div>
        <div class="">
          <div class="common-title">TELEGRAM</div>
          <div class="common-text">
            <a
              href="https://t.me/+wRGIFNeHcGdmNzE1"
              target="_blank"
              rel="noopener noreferrer"
              style="color: #fff">
              @ACE_keys</a
            >
          </div>
        </div>
      </div>
      <div v-if="isMinDevice" class="mb-center">
        <div class="h-footer-item1" :class="{ 'mb-item1': isMinDevice }">
          <div class="common-title mb-comm-title">CONTACT INFO</div>
          <div class="">
            <div class="common-title mb-comm-title">ADDRESS：</div>
            <div class="common-text mb-comm-text">
              591 Mudan Street, Yuen Long, Yuen Long, Hong Kong
            </div>
          </div>
          <div class="">
            <div class="common-title mb-comm-title">PHONE：</div>
            <div class="common-text mb-comm-text">+00852-6362833</div>
          </div>
          <div class="">
            <div class="common-title mb-comm-title">EMAIL：</div>
            <div class="common-text mb-comm-text">
              <a
                href="mailto:ACEsoftKeys@yeah.net"
                target="_blank"
                rel="noopener noreferrer"
                style="color: #cbcbcb"
                >ACEsoftKeys@yeah.net</a
              >
            </div>
          </div>
          <div class="">
            <div class="common-title mb-comm-title">TELEGRAM</div>
            <div class="common-text mb-comm-text">
              <a
                href="https://t.me/+wRGIFNeHcGdmNzE1"
                target="_blank"
                rel="noopener noreferrer"
                style="color: #cbcbcb">
                @ACE_keys</a
              >
            </div>
          </div>
        </div>
        <div class="mb-item1">
          <div>
            <div class="common-title mb-comm-title">METHOD OF PAYMENT</div>
            <img
              class="img"
              src="../../../assets/images/pc/pc-footer-pay.png"
              alt=""
              style="width: 7.6rem" />
          </div>
          <div class="">
            <div class="common-title mb-comm-title">POPULAR TAGS</div>
            <div class="common-tag flex-s">
              <div class="tag-item" v-for="item in homeGoods" :key="item.id">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isMinDevice" class="h-footer-item1">
        <div class="">
          <div class="common-title">POPULAR TAGS</div>
          <div class="common-tag flex-s">
            <div class="tag-item" v-for="item in homeGoods" :key="item.id">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isMinDevice" class="h-footer-item1">
        <div class="common-title">METHOD OF PAYMENT</div>
        <img
          src="../../../assets/images/pc/pc-footer-pay.png"
          alt=""
          style="width: 100%" />
      </div>
      <div class="h-footer-item1" v-if="!isMinDevice">
        <!-- <div class="common-title">CONTACT INFO</div> -->
        <div class="">
          <div class="common-title">SUBSCRIBE NEWSLETTER</div>
          <div class="common-text">
            Get all the latest information on sales and offers and stay up to
            date with our news. Sign up for newsletter
          </div>
          <div class="sub-input">
            <el-input placeholder="Email address" v-model="email"></el-input>
          </div>
          <div @click="submitOnClick" class="sub-btn point">SUBSCRIBE</div>
        </div>
      </div>
      <div
        v-if="isMinDevice"
        class="common-title mb-comm-title"
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        ">
        SUBSCRIBE NEWSLETTER
      </div>
      <div v-if="isMinDevice" class="mb-item3">
        <div class="mb-item3-l">
          <div class="common-text mb-comm-text">
            Get all the latest information on sales and offers and stay up to
            date with our news. Sign up for newsletter
          </div>
        </div>
        <div class="mb-item3-r">
          <div class="sub-input mb-input">
            <el-input placeholder="Email address" v-model="email"></el-input>
          </div>
          <div @click="submitOnClick" class="sub-btn point mb-sub">
            SUBSCRIBE
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { commonSubApi } from "@/network/api.js";
export default {
  data() {
    return {
      email: "",
    };
  },
  computed: {
    ...mapState(["homeGoods", "isMinDevice"]),
  },
  methods: {
    async submitOnClick() {
      const res = await commonSubApi("nameless", this.email, "from footer");
      this.$message.success("success");
    },
  },
};
</script>

<style lang="scss">
.comp-footer {
  .common-title {
    font-size: 0.22rem;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 0.1rem;
  }
  .mb-comm-title {
    font-size: 0.7168rem;
    margin-bottom: 0.7rem;
    // white-space: nowrap;
  }

  .common-text {
    font-size: 0.2rem;
    font-family: PingFang SC-Regular, PingFang SC;
    color: #ffffff;
    margin-bottom: 0.5rem;
  }
  .mb-comm-text {
    font-size: 0.61rem;
    color: #cbcbcb;
  }

  .sub-input {
    width: 3.28rem;
    height: 0.52rem;
    background: #153c69;
    border-radius: 0.49rem;
    margin-bottom: 0.25rem;

    .el-input {
      .el-input__inner {
        background-color: transparent;
        border: none;
        color: #fff;
      }
    }
  }

  .sub-btn {
    width: 1.92rem;
    height: 0.68rem;
    line-height: 0.68rem;
    border-radius: 0.66rem;
    font-size: 0.24rem;
    background: #2062ae;
    font-weight: 600;
    text-align: center;
    color: #fff;
  }

  .home-footer {
    background: rgba(0, 37, 80, 1);
    // height: 7.37rem;
    padding: 1.23rem 1.64rem;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .h-footer-item1 {
      width: 3rem;
      flex-shrink: 0;

      .common-tag {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .tag-item {
          // width: 1.23rem;
          height: 0.32rem;
          border: 1px solid rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          font-size: 0.2rem;
          padding: 0 0.1rem;
          margin-bottom: 0.1rem;
          margin-right: 0.12rem;
          color: #ffffff;
          white-space: nowrap;
        }
      }
    }
  }
}
.mb-home-footer {
  padding: 0.2rem;
  display: flex;
  flex-wrap: wrap;
  .mb-center {
    display: flex;
    justify-content: space-between;
    .mb-item1 {
      width: 50%;
      .common-tag {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .tag-item {
          // width: 1.23rem;
          height: 0.97rem;
          border: 0.01rem solid rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          font-size: 0.6rem;
          padding: 0 0.1rem;
          margin-bottom: 0.1rem;
          margin-right: 0.12rem;
          color: #cacaca;
        }
      }
      .img {
        margin-bottom: 1.0241rem;
      }
    }
  }
  .mb-item3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.15rem;
    .mb-item3-l {
      width: 50%;
    }
    .mb-item3-r {
      width: 50%;
      height: 4.2rem;
      display: flex;
      align-content: space-between;
      flex-wrap: wrap;
      .mb-input {
        width: 7.6rem;
        height: 1.4rem;

        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        line-height: 1.4rem;
        .el-input {
          font-size: 0.7rem;
        }
      }
      .mb-sub {
        width: 5rem;
        height: 1.5rem;
        font-size: 0.7rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 1.5rem;
      }
    }
  }
}
</style>
